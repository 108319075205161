/**
 * = Backgrounds
 */

@each $color, $value in $colors {
	@include bg-variant(".bg-#{$color}", $value);
}

@each $color, $value in $theme-colors {
	@include bg-gradient-variant(".bg-gradient-#{$color}", $value);
}

@each $color, $value in $theme-colors { 
	@include bg-gradient-variant(".bg-gradient-#{$color}", $value);
}

// Backgrounds Overlay
@each $color, $value in $theme-colors {
    .overlay-#{$color}{ 
        &:before{
            position: absolute;
            content:"";
            background: $value;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0.5;
            z-index: 0;
        }
    }

    .overlay-soft-#{$color}{
        &:before{
            position: absolute;
            content:"";
            background: $value;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0.3;
            z-index: 0;
        }
    }
}

@each $color, $value in $theme-colors {
    @include bg-variant(".section-#{$color}", $value);
}

.section-image {
    @include background-image(no-repeat, cover); 
}

.bg-img-holder {
    position: absolute;
    height: 100%;
    min-height: 20rem;
    background-repeat: no-repeat;
    z-index: -1;
}
