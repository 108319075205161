/**
 * = Cards
 */

.card-img,
.card-img-top {
    border-radius:0
}

.card-img,
.card-img-bottom {
    border-radius:0
}

.card {
    position: relative;
    .card-header{
        background: transparent;
    }

    .price-list {
        .list-group-item {
            span {
                width: 26px;
                font-size: $font-size-base;
            }
        }
    }
}

.profile-page {
    .card-profile {
        margin-top: -150px;

        .card-profile-image {
            position: relative;

            img {
                max-width: 180px;
                @include border-radius($border-radius);
                transform: translate(-50%,-30%);
                position: absolute;
                left: 50%;
                transition: $transition-base;

                &:hover {
                    transform: translate(-50%, -33%);
                }
            }
        }

        .card-profile-stats {
            padding: 1rem 0;

            > div {
                text-align: center;
                margin-right: 1rem;
                padding: .875rem;

                &:last-child {
                    margin-right: 0;
                }

                .heading {
                    display: block;
                    font-size: 1.1rem;
                    font-weight: bold;
                }
                .description {
                    font-size: .875rem;
                    color: $gray-500;
                }
            }
        }

        .card-profile-actions {
            padding: .875rem;
        }

        @include media-breakpoint-down(xs) {
            .card-profile-actions {
                margin-top: 110px;
            } 
        }

        @include media-breakpoint-between(sm, md) {
            .card-profile-stats {
                margin-top: 30px;
            }
        }
    }
}

.card-footer{
    background-color:transparent;
}

// Card with blockquotes

.card {
    .card-blockquote {
        position: relative;
        padding: 2rem;

        .svg-bg {
            display: block;
            position: absolute;
            width: 100%;
            height: 95px;
            top: -94px;
            left: 0;
        }
    }
}

.page-preview {

    display: block;
    position: relative;

    .show-on-hover {
        position: absolute;
        bottom: -33px;
        background: $primary;
        color: $dark;
        padding: 10px 0;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        width: calc(100% + 36px);
        left: -18px;
        opacity: 0;
        @include transition(.2s);
        @include box-shadow($shadow-inset);
    }

    &:hover {
        .show-on-hover {
            z-index: 99;
            opacity: 1;
        }
    }
}

// Animated cards

.card-lift-hover {
    &:hover {
        transform: translateY(-20px);
        @include transition($transition-base);
    }
}
