/**
 * = Custom forms
 */

.custom-control-label {
    // Background-color and (when enabled) gradient
    &:before {
        border: $custom-checkbox-indicator-border-width solid $custom-control-border-color;
        box-shadow: $custom-control-box-shadow;
        @if $enable-transitions {
            transition: $input-transition;
        } 
    }

    span {
        position: relative;
        top: 2px;
    }
} 

.custom-control-label {
    margin-bottom: 0;
}

.custom-control-input {
    &:active~.custom-control-label::before {
        border-color: $custom-control-indicator-active-border-color;
    }
}

// Custom File Input

.custom-file-label{
    border: $custom-control-border-width solid $custom-control-border-color;
    font-size: $input-font-size;
    color:$input-placeholder-color;
    @include box-shadow($box-shadow-soft);
    &::after {
        height: 100%;
        background-color: $dark;
        color:$white;
        border:0;
    }
}

.custom-file-input{
    &:not(:disabled):hover {
        cursor: pointer;
    
        & ~ .custom-file-label,
        & ~ .custom-file-label:before {
          border-color: $gray-300;
        }
    }
}


//Select
.custom-select{
    font-size: $input-btn-font-size;
    box-shadow: $input-box-shadow;
    border:0;

    &.custom-select-shadow {
        box-shadow: $input-box-shadow;
        border: 0;
        transition: box-shadow .15s ease;
    
    }

    &:hover {
        cursor: pointer;
    }
}

.custom-switch {
    padding-left: $custom-switch-width + $custom-control-gutter;
  
    .custom-control-label {
        &::before {
            left: -($custom-switch-width + $custom-control-gutter);
            width: $custom-switch-width;
            pointer-events: all;
            // stylelint-disable-next-line property-blacklist
            border:0;
            border-radius: $custom-switch-indicator-border-radius;
            @include box-shadow($shadow-inset);
        }
    
        &::after {
            top: calc(#{(($font-size-base * $line-height-base - $custom-control-indicator-size) / 2)} + #{$custom-control-indicator-border-width * 2});
            left: calc(#{-($custom-switch-width + $custom-control-gutter)} + #{$custom-control-indicator-border-width * 2});
            width: $custom-switch-indicator-size;
            height: $custom-switch-indicator-size;
            background-color: $custom-control-indicator-border-color;
            // stylelint-disable-next-line property-blacklist
            border-radius: $custom-switch-indicator-border-radius;
            @include transition(transform .15s ease-in-out, $custom-forms-transition);
        }
    }
  
    .custom-control-input:checked ~ .custom-control-label {

        &::before{
            @include box-shadow($shadow-inset);
        }
        &::after {
            background-color: $dark;
            transform: translateX($custom-switch-width - $custom-control-indicator-size);
        }
    }
  
    .custom-control-input:disabled {
        &:checked ~ .custom-control-label{
            &::before {
                background-color: $custom-control-indicator-checked-disabled-bg;
            }
            &::after{
                background-color: $dark;
                opacity: .4;
            }
        }
    }
}


