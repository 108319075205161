/**
 * = Breadcrumbs
 */

.breadcrumb-item {

  &, a {
    color: $breadcrumb-item-color;
    font-weight: $breadcrumb-font-weight;
    font-size: $font-size-xs;
    @include media-breakpoint-up(sm){
      font-size: $font-size-base;
    }
  }

  &.active {
    font-weight: $breadcrumb-active-font-weight;
  }

  + .breadcrumb-item {
    &:before{
      content: "\f101";
      font-family: $font-awesome-5;
      font-weight: 900;
      font-size: .625rem;
      color: $breadcrumb-divider-color;
    }
  }
  
}

.breadcrumb-transparent {
  background: transparent;
  padding: 0;
}

@each $color, $value in $theme-colors {
  .breadcrumb-#{$color}  {
      background-color: $primary;
      @include box-shadow($shadow-inset);
      .breadcrumb-item{
          a{
            color: $value;
          }
          &::before{
            color:$value;
          }
          &.active {
              color: $gray;
          }
      }
      &.breadcrumb-transparent {
        background: transparent;
        box-shadow: none;
        .breadcrumb-item {
          &.active {
            color: $value;
          }
        }
      }
  }
}

.breadcrumb-text-light {
  .breadcrumb-item {
    &, a {
      color: $breadcrumb-item-light-color;
    }

    &:before {
      color: $breadcrumb-divider-light-color;
    }
  }
}
